const DIMENSION_UNIT = 'mm';
const WEIGHT_UNIT = 'kg';

export function dimensionTo(number, unitFrom, unitTo = '') {
  const definedUnits = ['m', 'cm', 'mm', 'in', 'yd'];
  let result = 0;

  if (isNaN(number) || !unitFrom || !definedUnits.includes(unitFrom)) {
    return result;
  }

  if (!unitTo || !definedUnits.includes(unitTo)) {
    unitTo = DIMENSION_UNIT;
  }

  if (!unitFrom || !unitTo) {
    return result;
  }

  if (unitFrom === unitTo) {
    return number;
  }

  const conversionRates = {
    m: { cm: 100, mm: 1000, in: 39.3701, yd: 1.09361 },
    cm: { m: 0.01, mm: 10, in: 0.393701, yd: 0.0109361 },
    mm: { m: 0.001, cm: 0.1, in: 0.0393701, yd: 0.00109361 },
    in: { m: 0.0254, cm: 2.54, mm: 25.4, yd: 0.0277778 },
    yd: { m: 0.9144, cm: 91.44, mm: 914.4, in: 36 },
  };

  if (conversionRates[unitFrom] && conversionRates[unitFrom][unitTo]) {
    result = number * conversionRates[unitFrom][unitTo];
  }

  return result;
}

function weightTo(number, unitFrom, unitTo = '') {
  const definedUnits = ['kg', 'g', 'lbs', 'oz'];
  let result = 0;

  if (isNaN(number) || !unitFrom || !definedUnits.includes(unitFrom)) {
    return result;
  }

  if (!unitTo || !definedUnits.includes(unitTo)) {
    unitTo = WEIGHT_UNIT;
  }

  if (!unitFrom || !unitTo) {
    return result;
  }

  if (unitFrom === unitTo) {
    return number;
  }

  const conversionRates = {
    kg: { g: 1000, lbs: 2.20462, oz: 35.274 },
    g: { kg: 0.001, lbs: 0.00220462, oz: 0.035274 },
    lbs: { kg: 0.453592, g: 453.592, oz: 16 },
    oz: { kg: 0.0283495, g: 28.3495, lbs: 0.0625 },
  };

  if (conversionRates[unitFrom] && conversionRates[unitFrom][unitTo]) {
    result = number * conversionRates[unitFrom][unitTo];
  }

  return result;
}

export function calculateProductsPackage(products) {
  if (!products || products?.length === 0) {
    return;
  }

  const wcDimensionUnit = DIMENSION_UNIT;
  const wcWeightUnit = WEIGHT_UNIT;

  let packageDetails = {};
  let totalLength = 0,
    totalWeight = 0,
    cubicSize = 0,
    totalCost = 0;
  let validItemsCount = 0,
    totalValidItemsCount = 0;
  let addPallet = false;

  for (let item of products) {
    const product = item?.node;

    const boxCubicMeta = product?.variant?.boxCubic;
    let boxCubicObj = {}
    try {
      boxCubicObj = boxCubicMeta
      ? JSON.parse(boxCubicMeta.value)
      : null;

      if(typeof boxCubicObj === 'string') {
        boxCubicObj = JSON.parse(boxCubicObj)
      }
    } catch (e) {
      console.log('Error parsing boxCubicMeta', e);
    }

    const dimensions = {
      length: Number(boxCubicObj?._length?.toFixed(2) ?? 0),
      width: Number(boxCubicObj?._width?.toFixed(2) ?? 0),
      height: Number(boxCubicObj?._height?.toFixed(2) ?? 0),
    };

    const qty = Number(product?.quantity);
    const weight = Number(product?.variant?.weight);

    if (
      !dimensions?.length ||
      !dimensions?.width ||
      !dimensions?.height ||
      !weight
    ) {
      continue;
    }

    const price = !isNaN(product?.variant?.price) ? product.variant.price : Number(product?.variant?.price?.value ?? 0)
    let boxQty = Number(boxCubicObj?.box_qty ?? 0);
    const cubicFullStack = Number(
      boxCubicObj?.cubic_full_stack?.toFixed(2) ?? 0
    );
    const cubicFullStackQty = Number(
      boxCubicObj?.cubic_full_stack_qty?.toFixed(2) ?? 0
    );
    const palletOnly =
      boxCubicObj?._is_pallet === 'false' ||
      boxCubicObj?._is_pallet === false ||
      !boxCubicObj?._is_pallet
        ? false
        : true;

    if (isNaN(boxQty) || boxQty < 0 || boxQty >= cubicFullStackQty) {
      boxQty = 0;
    }

    const cubicOne = Number(boxCubicObj?.cubic_one?.toFixed(2) ?? 0);
    const cubicOnePlus = Number(boxCubicObj?.cubic_one_plus?.toFixed(2) ?? 0);

    totalWeight += weight * qty;

    if (palletOnly === 'Yes') {
      addPallet = true;
    }

    const mSizesData = {
      length: dimensionTo(dimensions.length, wcDimensionUnit, 'm'),
      width: dimensionTo(dimensions.width, wcDimensionUnit, 'm'),
      height: dimensionTo(dimensions.height, wcDimensionUnit, 'm'),
    };

    const pcubicOne = mSizesData.length * mSizesData.width * mSizesData.height;

    const pcubic = calculateCubicSize(
      qty,
      boxQty,
      cubicOne,
      cubicOnePlus,
      cubicFullStack,
      cubicFullStackQty,
      pcubicOne
    );

    if (pcubic <= 0) {
      continue;
    }

    cubicSize += pcubic;
    validItemsCount++;
    totalValidItemsCount += qty;
    totalCost += price * qty;
  }

  if (cubicSize > 0) {
    totalLength = dimensionTo(Math.cbrt(cubicSize), 'm', DIMENSION_UNIT);
    packageDetails = {
      length: totalLength,
      width: totalLength,
      height: totalLength,
      weight: weightTo(totalWeight, wcWeightUnit, wcDimensionUnit),
      cubicSize: cubicSize?.toFixed(2),
      palletOnly: addPallet,
      itemsCount: validItemsCount,
      totalItemsCount: totalValidItemsCount,
      totalCost: totalCost,
    };
  }

  return packageDetails;
}

export function calculateCubicSize(
  qty,
  boxQty,
  cubicOne,
  cubicOnePlus,
  cubicFullStack,
  cubicFullStackQty,
  pcubicOne
) {
  if (qty === 1) {
    return pcubicOne;
  } else if (qty <= boxQty) {
    return pcubicOne;
  } else if (cubicOnePlus) {
    if (qty <= cubicFullStackQty) {
      return cubicOne + cubicOnePlus * qty;
    } else {
      const bundles = Math.floor(qty / cubicFullStackQty);
      const remainingQty = qty - cubicFullStackQty * bundles;
      return bundles * cubicFullStack + cubicOne + cubicOnePlus * remainingQty;
    }
  } else {
    if (qty <= cubicFullStackQty) {
      return cubicOne;
    } else {
      const bundles = Math.ceil(qty / cubicFullStackQty);
      return bundles * cubicFullStack;
    }
  }
}

function prepareCubicDims(items) {
  let totalLength = 0;
  let totalWeight = 0;
  let cubicSize = 0;
  let addPallet = false;
  let totalItemCounts = 0;
  let sizes = {};

  // Getting custom fields from Shopify for the items

  items.forEach((item) => {
    let sku = item.sku || item.name || '';
    let qty = item.quantity || 1;
    let weight = item.weight || 0;

    if (!sku) return;

    let itemDims = undefined;

    const findMetafield = item.boxCubic ? item.boxCubic : item.metafields.find(
      (field) => field.key === '_boxCubic'
    );


    if (findMetafield) itemDims = JSON.parse(JSON.parse(findMetafield.value));

    if (!itemDims) return;

    let {
      _is_pallet,
      _length,
      _width,
      _height,
      box_qty,
      cubic_full_stack,
      cubic_full_stack_qty,
      cubic_one,
      cubic_one_plus,
    } = itemDims;
    if (_is_pallet) addPallet = true;
    let pweight = weight;
    let pcubic = 0;

    if (!_length || !_width || !_height || !weight) return;

    box_qty = box_qty || 0;
    cubic_full_stack = cubic_full_stack || 0;
    cubic_full_stack_qty = cubic_full_stack_qty || 1;

    if (box_qty >= cubic_full_stack_qty) {
      box_qty = 0;
    }

    cubic_one = cubic_one || 0;
    cubic_one_plus = cubic_one_plus || 0;
    totalWeight += pweight * qty;
    totalItemCounts += qty;

    // Convert dimensions from mm to cm
    [_length, _width, _height] = [_length / 10, _width / 10, _height / 10];

    // Calculate cubic meters
    let pcubic_one = (_length * _width * _height) / 1000000;

    if (qty === 1) {
      pcubic = pcubic_one;
    } else {
      if (qty <= box_qty) {
        pcubic = pcubic_one;
      } else if (cubic_one_plus) {
        if (qty <= cubic_full_stack_qty) {
          pcubic = cubic_one + cubic_one_plus * qty;
        } else {
          let bundles = Math.floor(qty / cubic_full_stack_qty);
          let remaining_qty = qty - cubic_full_stack_qty * bundles;
          pcubic =
            bundles * cubic_full_stack +
            cubic_one +
            cubic_one_plus * remaining_qty;
        }
      } else {
        if (qty <= cubic_full_stack_qty) {
          pcubic = cubic_one;
        } else {
          let bundles = Math.ceil(qty / cubic_full_stack_qty);
          pcubic = bundles * cubic_full_stack;
        }
      }
    }

    if (!pcubic) return;

    cubicSize += pcubic;
  });

  if (cubicSize) {
    if (addPallet || cubicSize > 0.8) {
      let palletsSize =
        cubicSize === 0
          ? 0
          : cubicSize < 0.5
          ? 0.1
          : Math.ceil(cubicSize / 3) * 0.6;
      let palletsWeight =
        cubicSize === 0
          ? 0
          : cubicSize < 0.5
          ? 0.6
          : Math.ceil(cubicSize / 3) * 0.2;

      cubicSize += palletsSize;
      totalWeight += palletsWeight;
    }

    totalLength = Math.cbrt(cubicSize) * 100; // Convert cubic meters to centimeters
    sizes = {
      name: 'Custom Item',
      length: Number(totalLength.toFixed(2)),
      width: Number(totalLength.toFixed(2)),
      height: Number(totalLength.toFixed(2)),
      weight: Number(totalWeight.toFixed(2)),
      itemType: addPallet || cubicSize > 0.8 ? 'Pallet' : 'Carton',
      cubicSize: cubicSize,
    };
  }

  return { sizes, totalItemCounts };
}


export function postMapBoxItems(items) {
  let { sizes, totalItemCounts } = prepareCubicDims(items);

  if (!sizes) {
    return null;
  }

  // cubicSize = Number(cubicSize) || 0;

  let { weight, cubicSize } = sizes;
  let length_limit = 230; // 2300 / 10
  let width_limit = 230;
  let height_limit = 250;
  let cubic_limit = (length_limit * width_limit * height_limit) / 1000000;
  let line_items = [];

  let cube_multiplier = 1.15; // Increase size by 15%
  let weight_multiplier = 1.15; // Increase weight by 15%

  if (cubic_limit > 0 && cubicSize > cubic_limit) {
    let lines_count = Math.ceil(cubicSize / cubic_limit);
    if (lines_count > totalItemCounts) {
      lines_count = totalItemCounts;
    }

    let weight_each = weight / lines_count;

    for (let i = 0; i < lines_count; i++) {
      let is_last_item = i === lines_count - 1;
      let remaining_cubic = cubicSize - (cubic_limit * i);
      let current_cubic = is_last_item ? remaining_cubic : cubic_limit;
      
      let length = Math.cbrt(current_cubic * 1000000); // Convert cubic meters to cm

      line_items.push({
        name: `Furnlink Package ${i + 1}`,
        itemType: sizes.itemType,
        quantity: 1,
        length: Number((Math.min(length, length_limit) * cube_multiplier).toFixed(2)),
        width: Number((Math.min(length, width_limit) * cube_multiplier).toFixed(2)),
        height: Number((Math.min(length, height_limit) * cube_multiplier).toFixed(2)),
        weight: Number((weight_each * weight_multiplier).toFixed(2)),
      });
    }
  } else {
    line_items.push({
      name: 'Furnlink Package',
      itemType: sizes.itemType,
      quantity: 1,
      length: Number((sizes.length * cube_multiplier).toFixed(2)),
      width: Number((sizes.width * cube_multiplier).toFixed(2)),
      height: Number((sizes.height * cube_multiplier).toFixed(2)),
      weight: Number((sizes.weight * weight_multiplier).toFixed(2)),
    });
  }

  return line_items.length ? line_items : items;
}